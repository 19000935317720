<template>
  <div id="data-model-container" class="vs-con-loading__container">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table stripe :data="itemData.items" :hover-flat="true">
        <template slot="header">
          <div class="header-title-container flex items-center">
            <!-- ADD NEW -->
            <feather-icon 
              icon="ArrowLeftIcon" 
              class="cursor-pointer mr-2"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              @click.stop="goBack()" 
            />
            <span class="header-title">Case Document List</span>
          </div>
          <div>Firm: {{itemData.lawFirmName}}</div>
          <div>Case: {{itemData.caseName}}</div>
        </template>
          <template slot="thead">
            <vs-th v-for="header in headerItems" :key="header.label" class="text-center">
              {{header.label}}
              <vs-dropdown v-if="header.label=='Actions'" class="mx-2 mt-1">
                <vs-icon class="" icon="sort"></vs-icon>
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="sortBy('fileName')">
                    Alphabetically
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="sortBy('createdOn')">
                    Date Added
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="sortBy('isReadyForSlicing')">
                    Slicer Ready
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="sortBy('isSliced')">
                    Sliced
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr 
                :data="tr" 
                :key="indextr" v-for="(tr, indextr) in itemData.items"
              >
                <vs-td>
                  <p class="product-name font-medium">{{ tr.fileName }}</p>
                </vs-td>
                <vs-td>
                  <p>{{formatDate(tr.createdOn)}}</p>
                </vs-td>
                <vs-td>
                  <p>{{tr.status}}</p>
                </vs-td>
                <vs-td>
                  <p>{{tr.isSliced ? "Yes" : "No"}}</p>
                </vs-td>
                <vs-td>
                  <p>{{tr.isReadyForSlicing && tr.isSliced ? "Sliced Already": (tr.isReadyForSlicing ? "Yes": "In Progress")}}</p>
                </vs-td>
                <vs-td>
                  <vx-tooltip text="PDF Slicer" position="left" color="#1a1a1a" v-if="tr.isReadyForSlicing && !tr.isSliced">
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-2"
                                  @click.stop="previewCaseDocumentSlicer(tr)" />
                  </vx-tooltip>
                  <vx-tooltip text="Screenshots" position="left" color="#1a1a1a" v-if="tr.isSliced">
                    <feather-icon icon="CameraIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-2"
                                  @click.stop="previewCaseDocumentScreenshots(tr)" />
                  </vx-tooltip>
                  <vx-tooltip text="Key Data Report" position="left" color="#1a1a1a" v-if="tr.isSliced">
                    <feather-icon icon="ListIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-2"
                                  @click.stop="previewCaseDocumentKeyDataReport(tr)" />
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
    </div>
  </div>
</template>

<script>
import moduleTodo from '@/store/todo/moduleTodo.js'
import _ from 'lodash'

export default {
  components: {
    
  },
  data () {
    return {
      itemData: {items: []},
      headerItems: [
        {label: "File Name", sortKey: "fileName"}, 
        {label: "Created On", sortKey: 'createdOn'}, 
        {label: "Status", sortKey: 'status'}, 
        {label: "Sliced", sortKey: 'isReadyForSlicing'}, 
        {label: "Slicer Ready", sortKey: 'isSliced'}, 
        {label: "Actions", sortKey: 'createdOn'}
      ],
      accountId: this.$route.params.accountId,
      caseId: this.$route.params.caseId,
      sortKey: 'createdOn'
    }
  },
  created() {
    if (!this.$store.hasModule("todo")) {
      this.$store.registerModule('todo', moduleTodo)
    }
  },
  mounted () {
    this.$vs.loading({
      container: '#data-model-container',
    });
    this.$store.dispatch('todo/fetchCaseDocumentList', {
          accountId: this.accountId,
          caseId: this.caseId
        }).then(response => {
          if (response && response.items) {
            response.items.sort((a, b) => {
              try {
                return b.createdOn - a.createdOn
              } catch(error) {return 0;}
            });
          }
          this.itemData = response
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        }).catch(error => {
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        });
  },
  methods: {
    sortBy(key) {
      console.log(key)
      try {
        const items = this.itemData.items
        items.sort((a, b) => {
          try {
            return b[key] - a[key]
          } catch (error) {
            return 0;
          }
        })
        if (this.sortKey == key) {
          items.reverse()
        } else {
          this.sortKey = key
        }
        this.itemData.items = _.cloneDeep(items)
      } catch (error) {}
    },
    handleNavigation() {
      try {
        if (this.$route.query.source) {
          switch(this.$route.query.source) {
            case "home":
              this.$router.push({name: this.$route.query.source, query: {selectedTab: "cases"}})
              return true;
          }
        }
      } catch(error) {}
      return false;
    },
    goBack() {
      let result = this.handleNavigation();
      if (!result) {
        this.$router.push({name: "account-cases", params: {accountId: this.accountId}});
      }
    },  
    formatDate(value) {
      if (value && this.moment)
        return this.moment(value).format('DD/MM/YYYY')
      else return ''
    },
    previewCaseDocumentKeyDataReport(documentRecord) {
      this.$router.push({
        name: "case-document-keydata", 
        params: {
          accountId: this.accountId,
          caseId: this.caseId,
          documentId: documentRecord.uuid
        },
        query: {
          source: "account-case-documents"
        }
      });
    },
    previewCaseDocumentScreenshots(documentRecord) {
      this.$router.push({
        name: "case-document-screenshots", 
        params: {
          accountId: this.accountId,
          caseId: this.caseId,
          documentId: documentRecord.uuid
        },
        query: {
          source: "account-case-documents"
        }
      });
    },
    previewCaseDocumentSlicer(documentRecord) {
      this.$router.push({
        name: "case-document-pdfslicer", 
        params: {
          accountId: this.accountId,
          caseId: this.caseId,
          documentId: documentRecord.uuid
        },
        query: {
          source: "account-case-documents"
        }
      });
    }
  },
}
</script>

<style>
  .vs-tooltip{
    padding: 8px;
  }
  .con-vs-tooltip {
    display: inline-block;
  }
  #data-list-list-view .vs-con-table .vs-table--header {
    margin: 1rem;
    font-size: 12px;
  }

  .vs-table--search {
    max-width: 100%;
    width: 100%;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 0.65rem 2rem;
    width: 100%;
    margin-right: 18px;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input + i,
  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input:focus + i {
    left: 0.5rem;
  }

  .vs-table--td {
    padding: 10px 20px !important;
  }

  .header-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-title-container {
    flex-grow: unset;
  }

  .header-title-container .feather-icon {
    height: 20px;
    margin-right: 8px;
  }

  .vs-table--tbody-table .tr-values.selected {
    cursor: auto;
  }

  .vs-table--tbody-table a {
    cursor: pointer;
    text-decoration: underline;
  }
</style>